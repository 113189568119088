import(/* webpackMode: "eager" */ "/app/node_modules/@radix-ui/react-accordion/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@storyblok/react/dist/live-editing.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["ArrowLink"] */ "/app/src/components/blocks/components/ArrowLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonLink"] */ "/app/src/components/blocks/components/ButtonLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Chip"] */ "/app/src/components/blocks/components/Chip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeepLink"] */ "/app/src/components/blocks/components/DeepLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IconCard"] */ "/app/src/components/blocks/components/IconCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KollarnaIframeCta"] */ "/app/src/components/blocks/components/KollarnaIframeCta.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/components/MedicalApprovedBadge.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/sections/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DigitalCareCard"] */ "/app/src/components/blocks/sections/DigitalCareCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorMessage"] */ "/app/src/components/blocks/sections/ErrorMessage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/sections/FeaturedArticles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormSection"] */ "/app/src/components/blocks/sections/FormSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GetListedBlock"] */ "/app/src/components/blocks/sections/GetListedBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GetTheApp"] */ "/app/src/components/blocks/sections/GetTheApp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Snackbar"] */ "/app/src/components/blocks/sections/Snackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VaccinationBusLocationPage"] */ "/app/src/components/blocks/templates/VaccinationBusLocationPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/CTAProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/storyblok-routes/articles/ArticleFiltering.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactClinicMap"] */ "/app/src/components/storyblok-routes/clinics/ContactClinicMap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClinicsList"] */ "/app/src/components/storyblok-routes/clinics/list/ClinicsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/storyblok-routes/fact-advice/ListItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/storyblok-routes/fact-advice/SearchBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/storyblok-routes/fact-advice/SideNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/storyblok-routes/press/PressreleasesList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VaccinationLocationList"] */ "/app/src/components/storyblok-routes/vaccination-location/VaccinationLocationList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui-library/carousel/CarouselSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FindHere"] */ "/app/src/components/ui-library/find-here/FindHere.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DownloadApps"] */ "/app/src/components/ui-library/footer/DownloadApps.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui-library/header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui-library/link/ArrowLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui-library/pagination/Pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui-library/section/SubscriptionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui-library/share/ShareArticle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui-library/tabs/PriceTableTabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/layout/DoktorIcons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigStoreInitializer"] */ "/app/src/store/ConfigStoreInitializer.tsx");
